<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
    
    <div class="container">
            <div class="row "style="padding-top: 100px">
                <div class=" col-lg-6" >
                    <p style="padding-top: 20px;font-size: 56px;font-weight: 700;">{{$t('404_page.OOPS!')}}</p>
                    <p style="margin-top: 20px;font-size: 42px;line-height: 1.1;color: #22272e;">{{$t('404_page.Page_not_found')}}...</p>
                    <h3 style="font-size: 26px;padding-top: 20px;color: #9d1d20;"> {{$t('404_page.Error')}}: 404 </h3> 
                    <!-- {{ error?.statusCode }} -->
                </div>
                <div class=" col-lg-6">
                    
                    <img src="https://www.smartoys.be/catalog//ext/css/smartoys/img/footer/error.png" alt="Image Error 404" width="100%">
                </div>
            </div>
        
            <!-- ....... -->
         
                <!-- ...... -->
                 <div class="row">
                    <div class="text-center">
                    <NuxtLinkLocale :to="{ path: '/' }" class="btn btn-solid">{{$t('404_page.Back_To_Homepage')}}</NuxtLinkLocale>
                </div>
                    <div class="border text-center align-middle " style="padding: 30px;margin-top: 50px;">
                        <p style="font-size: 18px;">{{$t('404_page.Experience_Problem')}}</p>
                        <NuxtLinkLocale :to="{ path: '/contact-us' }" class="btn btn-solid">{{$t('routes.contact-us') }}</NuxtLinkLocale>
                        <a class="btn btn-solid" href="https://www.facebook.com/clddistribution" target="_blank" ><i class="fa fa-facebook" aria-hidden="true"></i> {{$t('404_page.Join_Us')}}</a>
                    </div>
                 </div>

    </div>
</template>